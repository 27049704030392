import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'

import { slugify } from '../../utils/helpers'

const SimpleMenu = ({ handleClose, anchorEl, menuItems }) => {
  const handleOnClick = (action = () => {}) => {
    handleClose()
    action()
  }

  return (
    <Menu
      id='simple-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {menuItems.map(({ label, icon: Icon, action }) => (
        <MenuItem onClick={() => handleOnClick(action)} key={slugify(label)}>
          <ListItemIcon>
            <Icon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>
            {label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default SimpleMenu
