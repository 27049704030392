import React, { useState, useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import Warning from '@material-ui/icons/Warning'
import { yellow } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { PartnerProjectContext } from '../../providers/PartnerProjectProvider'
import LibraryActions from '../LibraryActions'
import LibraryFiles from '../LibraryFiles'
// import TrifectaBar from '../TrifectaBar'
import {
  GET_BUSINESS_PROJECTS,
  GET_PROJECT_FOLDER_CONTENTS,
  GET_AGENCY_BUSINESSES,
  DELETE_BUSINESS_PROJECTS,
  DELETE_PROJECT_FILES,
  RENAME_PROJECT
} from '../../graphql/partners'
import { testFilters } from '../../utils/helpers'
import * as actions from '../../providers/PartnerProjectProvider/actions-types'

const PartnerLibrary = ({ user, partner }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { dispatch, state } = useContext(PartnerProjectContext)
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [projectName, setProjectName] = useState('New Project')
  const [items, setItems] = useState({
    type: 'projects',
    folders: [],
    files: []
  })

  const {
    currentDirectory,
    sortQuery,
    search,
    filters,
    selectedItems,
    trashItems,
    projectFilters
  } = state
  const { type, path, id: directoryId } = currentDirectory

  let query = GET_BUSINESS_PROJECTS
  let variables = { id: path }

  if (['agency', 'business'].includes(partner.type)) {
    // case of entering root agency folder
    if (path === '' && type === 'businesses') {
      query = GET_AGENCY_BUSINESSES
      variables = { id: partner.id }
    }
    // case of entering a project
    if (type === 'projects') {
      query = GET_BUSINESS_PROJECTS
      variables = { id: path }
    }
    // case of entering a  folder
    if (type === 'folders') {
      query = GET_PROJECT_FOLDER_CONTENTS
      variables = { path, orderBy: sortQuery }
    }
  } else {
    query =
      currentDirectory.path === ''
        ? GET_BUSINESS_PROJECTS
        : GET_PROJECT_FOLDER_CONTENTS
    variables =
      currentDirectory.path === ''
        ? { id: partner.id }
        : { path, orderBy: sortQuery, id: directoryId }
  }

  const { data, loading, refetch } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables
  })

  const checkFilters = (name) =>
    filters.find((filter) => testFilters(filter, name))

  const getFolders = (userObjects, folderType = 'projects') => {
    let folders = []

    if (userObjects && userObjects.length > 0)
      folders = userObjects.reduce((acc, elem) => {
        const { name, id, folderPrefix } = elem
        return [
          ...acc,
          {
            name,
            id,
            path: folderType === 'projects' ? folderPrefix : id
          }
        ]
      }, [])

    return folders
  }

  useEffect(() => {
    const { projectFolderContent, businessProjects, businesses } = data || {}
    let updatedItems = { ...items }
    if (projectFolderContent) {
      updatedItems = {
        type: 'folders',
        files:
          projectFolderContent &&
          projectFolderContent.files.filter(
            (file) =>
              file.name.includes(search) && Boolean(checkFilters(file.name))
          )
      }
    }

    if (businessProjects) {
      updatedItems = {
        type: 'projects',
        folders: getFolders(businessProjects),
        files: []
      }
    }

    if (businesses) {
      updatedItems = {
        type: 'businesses',
        folders: getFolders(businesses, 'businesses'),
        files: []
      }
    }
    setItems(updatedItems)
  }, [data, projectFilters, filters])

  const [deleteProjects, { loading: deleteProjectsLoading }] = useMutation(
    DELETE_BUSINESS_PROJECTS,
    {
      onCompleted: async () => {
        await refetch()
        /**
         * TODO: UPDATE USER'S STORAGE AFTER FILES DELETE
         * Better use subscriptions to update apollo client when database updates
         * * Subscriptions have problems with GraphQLShield
         * */
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )

  const [deleteFiles, { loading: deleteFilesLoading }] = useMutation(
    DELETE_PROJECT_FILES,
    {
      onCompleted: async () => {
        await refetch()
        /**
         * TODO: UPDATE USER'S STORAGE AFTER FILES DELETE
         * Better use subscriptions to update apollo client when database updates
         * * Subscriptions have problems with GraphQLShield
         * */
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )

  const [renameProject] = useMutation(RENAME_PROJECT, {
    onCompleted: async () => {
      await refetch()
      /**
       * TODO: UPDATE USER'S STORAGE AFTER FILES DELETE
       * Better use subscriptions to update apollo client when database updates
       * * Subscriptions have problems with GraphQLShield
       * */
      enqueueSnackbar('Rename Successful!', { variant: 'success' })
      dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
    },
    onError: () => {
      enqueueSnackbar('Rename Failed!', { variant: 'error' })
      dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
    }
  })

  const handleDelete = () => {
    const files = []
    let ids = []
    if (type === 'projects') {
      ids = selectedItems.map((item) => item.id)
      return deleteProjects({
        variables: {
          ids
        }
      })
    }

    selectedItems.forEach(
      (item) => item.type !== ('folder' || 'projects') && files.push(item)
    )

    if (files.length)
      deleteFiles({
        variables: {
          filePaths: files.map((file) => file.path),
          folderPath: currentDirectory.path
        }
      })
  }

  return (
    <>
      {/* <TrifectaBar partner={partner} /> */}
      {user.statuses.filter(({ name }) => name === 'IS_VERIFIED').length > 0 ? (
        <>
          <LibraryActions
            addModalVisible={addModalVisible}
            libraryType='Partnership'
            addButtonName='ADD PROJECT'
            setAddModalVisible={setAddModalVisible}
            projectName={projectName}
            items={items}
            setProjectName={setProjectName}
            currentDirectory={currentDirectory}
            refetch={refetch}
            dispatch={dispatch}
            selectedItems={selectedItems}
            trashItems={trashItems}
          />
          <LibraryFiles
            libraryType='Partnership'
            items={items}
            loading={loading}
            refetch={refetch}
            state={state}
            dispatch={dispatch}
            deleteFilesLoading={deleteFilesLoading}
            handleDelete={handleDelete}
            deleteFoldersLoading={deleteProjectsLoading}
            role={partner && partner.type}
            renameProject={renameProject}
          />
        </>
      ) : (
        <Box display='flex' p={5} justifyContent='center' alignItems='center'>
          <Warning style={{ fontSize: 50, color: yellow[700] }} />
          <Typography variant='h5'>Please verify your email first</Typography>
        </Box>
      )}
    </>
  )
}

export default PartnerLibrary
