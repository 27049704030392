import gql from 'graphql-tag'

export const GET_PROJECT_FOLDER_CONTENTS = gql`
  query projectFolderContent(
    $path: String!
    $orderBy: UserLibraryOrderByInput
  ) {
    projectFolderContent(path: $path, orderBy: $orderBy) {
      files {
        name
        extension
        size
        lastModified
        url
        path
      }
    }
  }
`
export const GET_BUSINESS_PROJECTS = gql`
  query businessProjects($id: ID!) {
    businessProjects(id: $id) {
      id
      name
      folderPrefix
      createdAt
      updatedAt
    }
  }
`

export const DELETE_BUSINESS_PROJECTS = gql`
  mutation deleteManyPartnerProjects($ids: [ID!]) {
    deleteManyPartnerProjects(ids: $ids)
  }
`

export const GET_BUSSINESS_PARTNER = gql`
  query userBusinessPartner {
    userBusinessPartner {
      id
      name
    }
  }
`

export const GET_AGENCY_PARTNER = gql`
  query userAgencyPartner {
    userAgencyPartner {
      id
      businesses {
        id
        name
      }
    }
  }
`
export const GET_AGENCY_BUSINESSES = gql`
  query businesses {
    businesses {
      id
      name
    }
  }
`

export const UPLOAD_FILES_FOR_PROJECTS = gql`
  mutation uploadProjectFile($folderPath: String!, $fileName: String!) {
    uploadProjectFile(
      params: { folderPath: $folderPath, fileName: $fileName }
    ) {
      url
      fields {
        key
        value
      }
    }
  }
`

export const RENAME_PROJECT = gql`
  mutation updatePartnerProject($id: ID!, $name: String!) {
    updatePartnerProject(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`

export const CREATE_PARTNER_PROJECT = gql`
  mutation createPartnerProject($name: String!, $businessId: ID!) {
    createPartnerProject(input: { name: $name, businessId: $businessId }) {
      id
    }
  }
`
export const DELETE_PROJECT_FILES = gql`
  mutation deleteProjectFiles($filePaths: [String!]!, $folderPath: String!) {
    deleteProjectFiles(
      params: { filePaths: $filePaths, folderPath: $folderPath }
    )
  }
`
