import * as actions from './action-types'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_SEARCH:
      return {
        ...state,
        search: payload
      }
    case actions.SET_ORDER_BY:
      return {
        ...state,
        orderBy: payload
      }
    case actions.SET_TYPE:
      return {
        ...state,
        type: payload
      }
    case actions.SET_STATUS:
      return {
        ...state,
        status: payload
      }

    default:
      return state
  }
}

export default reducer
