import gql from 'graphql-tag'
import {
  planSubscriptionFragment,
  rendersSubscriptionFragment,
  storageSubscriptionFragment,
  userPlanFragment,
  userRendersFragment,
  userStorageFragment,
  userConsumptionFragment
} from './fragments/subscriptionFragments'

// plans
export const planSubscriptionsQuery = gql`
  query planSubscriptions {
    planSubscriptions {
      ${planSubscriptionFragment}
    }
  }
`

export const subscribeToPlanMutation = gql`
  mutation subscribeToPlan($id: ID!, $numOfMonths: Int!) {
    subscribeToPlan(input: { id: $id, numOfMonths: $numOfMonths }) {
      ${userPlanFragment}
    }
  }
`

export const unsubscribeFromPlanMutation = gql`
  mutation unsubscribeFromPlan {
    unsubscribeFromPlan {
      ${userPlanFragment}
    }
  }
`

export const currentPlanQuery = gql`
  query currentPlanSubscription {
    currentPlanSubscription {
      ${userPlanFragment}
    }
  }
`

// renders

export const rendersSubscriptionsQuery = gql`
  query rendersSubscriptions {
    rendersSubscriptions {
      ${rendersSubscriptionFragment}
    }
  }
`

export const purchaseRendersMutation = gql`
  mutation purchaseRenders($id: ID!) {
    purchaseRenders(id: $id) {
      ${userRendersFragment}
    }
  }
`

export const outstandingRendersQuery = gql`
  query outstandingPurchasedRenders {
    outstandingPurchasedRenders {
      ${userRendersFragment}
    }
  }
`

export const consumedUnwatermarkedRenderMutation = gql`
  mutation consumedUnwatermarkedRender {
    consumedUnwatermarkedRender {
      ${userConsumptionFragment}
    }
  }
`

export const consumedWatermarkedRenderMutation = gql`
  mutation consumedWatermarkedRender {
    consumedWatermarkedRender {
      ${userConsumptionFragment}
    }
  }
`

// storage

export const storageSubscriptionsQuery = gql`
  query storageSubscriptions {
    storageSubscriptions {
      ${storageSubscriptionFragment}
    }
  }
`

export const subscribeToStorageMutation = gql`
  mutation subscribeToStorage($id: ID!, $numOfMonths: Int!) {
    subscribeToStorage(input: { id: $id, numOfMonths: $numOfMonths }) {
      ${userStorageFragment}
    }
  }
`

export const unsubscribeFromStorageMutation = gql`
  mutation unsubscribeFromStorage($id: ID!) {
    unsubscribeFromStorage(id: $id) {
      ${userStorageFragment}
    }
  }
`

export const currentStorageQuery = gql`
  query currentStorageSubscription {
    currentStorageSubscription {
      ${userStorageFragment}
    }
  }
`

export const updateUserStorageMutation = gql`
  mutation updateUserStorageMutation($id: ID!, $newConsumedStorage: Float!) {
    updateUserStorage(id: $id, newConsumedStorage: $newConsumedStorage) @client
  }
`

// consumption

export const currentConsumptionQuery = gql`
  query currentConsumptionQuery {
    currentConsumption {
    ${userConsumptionFragment}
    }
  }
`
