import { useEffect } from 'react'

const useAbortExit = (disallowNavigateRef) => {
  useEffect(() => {
    const confirmationMessage = 'Changes you made may not be saved.'
    const beforeUnloadHandler = (e, disallowNavigate) => {
      if (!disallowNavigate.current) return ''
      ;(e || window.event).returnValue = confirmationMessage
      return confirmationMessage // Gecko + Webkit, Safari, Chrome etc.
    }

    window.addEventListener('beforeunload', (e) =>
      beforeUnloadHandler(e, disallowNavigateRef)
    )

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [disallowNavigateRef])
}

export default useAbortExit
