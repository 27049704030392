import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { getUserLibrary } from '../../graphql/userLibrary'
import initState from './init-state'
import reducer from './reducer'

export const UserLibraryContext = createContext({
  state: initState,
  dispatch: () => {},
  library: {}
})

const UserLibraryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const {
    data: { userLibrary },
    loading,
    refetch
  } = useQuery(getUserLibrary, {
    fetchPolicy: 'network-only',
    variables: {
      path: state.currentDirectory.path,
      orderBy: state.sortQuery,
      isTrash: state.showTrash
    }
  })

  return (
    <UserLibraryContext.Provider
      value={{
        state,
        dispatch,
        library: { refetch, userLibrary, loading }
      }}
    >
      {children}
    </UserLibraryContext.Provider>
  )
}

UserLibraryProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserLibraryProvider
