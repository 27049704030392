import React, { useState } from 'react'
import Router from 'next/router'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

import Image from '@material-ui/icons/Image'
import Videocam from '@material-ui/icons/Videocam'

import {
  UserTemplateContainer,
  SlideChild,
  UserTemplateOverLay
} from '../UserTemplate/UserTemplate.styles'
import SalesProjectActions from '../SalesProjectActions'

const SalesProject = ({ data }) => {
  const {
    price,
    paypalBuyUrl,
    caption,
    headline,
    customerName,
    customerEmail,
    status,
    userTemplate: { thumbnailUrl, name, type, id: templateId },
    id
  } = data

  const [showMenuIcon, setShowMenuIcon] = useState(false)

  const onTemplateEditClick = () =>
    Router.push(
      '/editor',
      `/editor/${type.slug.replace('-', '/')}/${templateId}`
    )

  return (
    <UserTemplateContainer
      onMouseEnter={() => setShowMenuIcon(true)}
      onMouseLeave={() => setShowMenuIcon(false)}
    >
      <img src={thumbnailUrl} width='100%' alt={name} />
      <Box p={1}>
        <Typography variant='subtitle2' gutterBottom>
          {name}
        </Typography>
        <Typography variant='subtitle1'>{customerName}</Typography>
        <Box justifyContent='flex-end' display='flex'>
          {type.typeName === 'UserImageTemplate' ? (
            <Image color='disabled' fontSize='small' />
          ) : (
            <Videocam color='disabled' fontSize='small' />
          )}
        </Box>
      </Box>
      <Slide direction='left' in={showMenuIcon} mountOnEnter unmountOnExit>
        <SlideChild>
          <SalesProjectActions
            id={id}
            type={type}
            templateId={templateId}
            status={status}
            projectInfo={{
              price,
              paypalBuyUrl,
              caption,
              headline,
              customerName,
              customerEmail
            }}
          />
        </SlideChild>
      </Slide>
      <Fade in={showMenuIcon}>
        <UserTemplateOverLay onClick={() => onTemplateEditClick()} />
      </Fade>
    </UserTemplateContainer>
  )
}

SalesProject.propTypes = {
  data: PropTypes.object
}

SalesProject.defaultProps = {
  data: {}
}
export default SalesProject
