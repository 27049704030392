import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'

import { getSelectedFiltersQuery } from '../graphql/templates'

const useSubmitTemplatesFilters = () => {
  const { refetch } = useQuery(getSelectedFiltersQuery)
  const { push } = useRouter()

  const submitFilters = async () => {
    const {
      data: { getSelectedFilters }
    } = await refetch()

    push({ pathname: '/templates/explore', query: getSelectedFilters })
  }

  return submitFilters
}

export default useSubmitTemplatesFilters
