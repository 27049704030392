import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import ListViewItem from './ListViewItem.component'
import { AlternateList } from './LibraryFilesList.styles'

const LibraryFilesList = ({
  folders,
  files,
  loading,
  selectedItems,
  trashItems,
  dispatch,
  type,
  currentDirectory,
  handleDirectoryChange,
  renameProject
}) => {
  const renderFolders =
    folders &&
    folders.map((folder) => (
      <ListViewItem
        key={folder.path}
        {...folder}
        type={type}
        selectedItems={selectedItems}
        trashItems={trashItems}
        dispatch={dispatch}
        renameProject={renameProject}
        handleDirectoryChange={handleDirectoryChange}
        currentDirectory={currentDirectory}
      />
    ))

  const renderFiles =
    files &&
    files.map((file) => (
      <ListViewItem
        key={file.path}
        {...file}
        type='file'
        selectedItems={selectedItems}
        trashItems={trashItems}
        dispatch={dispatch}
        currentDirectory={currentDirectory}
      />
    ))

  return (
    <>
      {!loading ? (
        <AlternateList dense disablePadding>
          {renderFolders}
          {renderFiles}
        </AlternateList>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          minHeight={200}
        >
          <Skeleton animation='wave' />
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton />
        </Box>
      )}
    </>
  )
}

export default LibraryFilesList
