import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'
import SalesProjectsList from '../SalesProjectsList'
import SalesProjectsFilters from '../SalesProjectsFilters'
import { SalesProjectsContext } from '../../providers/SalesProjectsProvider'
import { SET_STATUS } from '../../providers/SalesProjectsProvider/action-types'
import { pageSize } from '../../lib/constants/userTemplate'
import {
  GET_SALES_PROJECTS,
  UPDATE_USER_SALES_ACCOUNT
} from '../../graphql/salesSystem'
import AccountAvatar from '../AccountAvatar'

const SalesProjectsGroup = ({ account }) => {
  const {
    variablesState: { search, type, orderBy, status },
    dispatch
  } = useContext(SalesProjectsContext)
  const [salesAccount, setSalesAccount] = useState(account)

  const { id, logoUrl } = salesAccount

  const salesProjectsReturn = useQuery(GET_SALES_PROJECTS, {
    variables: {
      status,
      search,
      orderBy,
      accountId: id,
      first: pageSize
    }
  })

  const { refetch } = salesProjectsReturn

  const [updateSalesAccount] = useMutation(UPDATE_USER_SALES_ACCOUNT)
  const handleAvatarChange = async (url) => {
    const { data } = await updateSalesAccount({
      variables: {
        accountId: id,
        logoUrl: url
      }
    })
    setSalesAccount(data.updateSalesAccount)
  }

  useEffect(() => {
    refetch({
      search,
      type,
      orderBy,
      status,
      first: pageSize
    })
  }, [refetch, search, type, orderBy, status])

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        alignSelf='center'
        flexDirection='column'
        display='flex'
        alignItems='center'
      >
        <AccountAvatar
          avatar={logoUrl}
          onChange={handleAvatarChange}
          isCustomAvatar
        />
        <Typography variant='caption'>Click logo to change</Typography>
      </Box>
      <Box alignSelf='center' marginTop={2}>
        <ToggleButtonGroup
          size='small'
          value={status}
          exclusive
          onChange={(_, payload) => dispatch({ type: SET_STATUS, payload })}
          aria-label='projects state'
        >
          <ToggleButton value='PENDING'>Pending</ToggleButton>
          <ToggleButton value='APPROVED'>Approved</ToggleButton>
          <ToggleButton value='SOLD'>Sold</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <SalesProjectsFilters salesProjectsReturn={salesProjectsReturn} />
      <SalesProjectsList salesProjectsReturn={salesProjectsReturn} />
    </Box>
  )
}

export default SalesProjectsGroup
