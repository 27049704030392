import gql from 'graphql-tag'

export const faqQuery = gql`
  query faqQuery {
    faq {
      id
      question
      answer
    }
  }
`

export const getWebinars = gql`
  query webinars($page: Int!, $perPage: Int!, $category: WebinarCategory!) {
    webinars(page: $page, perPage: $perPage, category: $category) {
      result {
        title
        description
        avatar
        presenter
        url
        image
        startDate
        endDate
        webinarExtraInfo {
          image
          video
        }
      }
      totalPages
      webinarsCount
      hasNext
    }
  }
`

export const getBlogs = gql`
  query blogs($page: Int!, $perPage: Int!, $year: String!) {
    blogs(page: $page, perPage: $perPage, year: $year) {
      result {
        title
        issue
        blogUrl
        createdAt
      }
      totalPages
      blogsCount
      hasNext
    }
  }
`

export const getNewsLetters = gql`
  query newsLetters($page: Int!, $perPage: Int!, $year: String!) {
    newsLetters(page: $page, perPage: $perPage, year: $year) {
      result {
        id
        previewUrl
        downloadUrl
        notes
        createdAt
      }
      totalPages
      newsLettersCount
      hasNext
    }
  }
`

export const getAnnouncements = gql`
  query announcements {
    announcements {
      id
      title
      description
      url
      callToAction
      background
      cover
      lightMode
    }
  }
`

export const getMediaProviders = gql`
  query getMediaProviders {
    mediaProviders {
      id
      name
      logo
      hasVideos
      hasImages
    }
  }
`

export const getMediaProviderImages = gql`
  query getMediaProviderImages($input: MediaProviderImageInput!) {
    mediaProviderImages(input: $input) {
      images {
        previewUrl
        imageUrl
        width
        height
      }
      pages
    }
  }
`

export const getMediaProviderVideos = gql`
  query getMediaProviderVideos($input: MediaProviderVideoInput!) {
    mediaProviderVideos(input: $input) {
      videos {
        previewUrl
        videoUrl
        previewImageUrl
        width
        height
        duration
      }
      pages
    }
  }
`

export const getMediaProviderVectors = gql`
  query getMediaProviderVectors($input: MediaProviderVectorInput!) {
    mediaProviderVectors(input: $input) {
      vectors {
        id
        previewUrl
        vectorUrl
        categories {
          id
          name
        }
      }
      pages
    }
  }
`
export const getMediaProviderCategories = gql`
  query mediaProviderCategories($input: MediaProviderCategoryInput!) {
    mediaProviderCategories(input: $input) {
      categories {
        id
        name
      }
      pages
    }
  }
`

export const getUserRecentMedia = gql`
  query userRecentMedia($mediaType: String!, $page: Int!, $perPage: Int!) {
    userRecentMedia(mediaType: $mediaType, page: $page, perPage: $perPage) {
      result {
        mediaMeta
      }
      totalPages
      recentMediaCount
    }
  }
`

export const userRecentMediaMutation = gql`
  mutation CreateorUpdateRecentMedia($mediaType: String!, $mediaMeta: String!) {
    CreateorUpdateRecentMedia(mediaType: $mediaType, mediaMeta: $mediaMeta) {
      mediaMeta
    }
  }
`

export const downloadMediaProviderVector = gql`
  mutation mediaProviderVectorDownload(
    $input: MediaProviderVectorDownloadInput!
  ) {
    mediaProviderVectorDownload(input: $input) {
      svg
    }
  }
`
