import { useState, useEffect } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { extractScrollableContent } from '../utils/helpers'

const useInfiniteScroll = (
  { data, error, loading: itemsLoading, fetchMore },

  firstValue = 12,
  paginationContainer
) => {
  const [items, setItems] = useState([])
  const [loading, showLoading] = useState(false)
  const [lastPage, setLastPage] = useState(false)

  const [cursor, setCursor] = useState('')

  const scrollableContent = extractScrollableContent(data, paginationContainer)

  useEffect(() => {
    showLoading(itemsLoading)
    if (!itemsLoading && !error && scrollableContent) {
      const {
        edges,
        pageInfo: { endCursor, hasNextPage }
      } = scrollableContent

      setItems(edges)
      setCursor(endCursor)
      setLastPage(!hasNextPage)
    }
  }, [itemsLoading, scrollableContent])

  // hook for loading more items

  useBottomScrollListener(() => {
    if (lastPage === false) {
      showLoading(true)

      fetchMore({
        variables: {
          first: firstValue,
          after: cursor
        },
        updateQuery: (_, { fetchMoreResult }) => {
          const {
            edges,
            pageInfo: { endCursor, hasNextPage }
          } = extractScrollableContent(fetchMoreResult, paginationContainer)
          setItems([...items, ...edges])
          setCursor(endCursor)

          if (!hasNextPage) {
            setLastPage(true)
          }
        }
      })

      showLoading(false)
    }
  })

  return { items, setItems, error, loading, lastPage }
}

export default useInfiniteScroll
