import { useState, useEffect } from 'react'
import { extractScrollableContent } from '../utils/helpers'

const useLoadMore = (
  { data, error, loading: itemsLoading, fetchMore },

  firstValue = 12,
  paginationContainer
) => {
  const [items, setItems] = useState([])
  const [loading, showLoading] = useState(false)
  const [lastPage, setLastPage] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [cursor, setCursor] = useState('')

  const scrollableContent = extractScrollableContent(data, paginationContainer)

  useEffect(() => {
    showLoading(itemsLoading)
    if (!itemsLoading && !error && scrollableContent) {
      const {
        edges,
        pageInfo: { endCursor, hasNextPage, hasPreviousPage }
      } = scrollableContent
      if (!hasPreviousPage) {
        setCurrentPage(1)
      }
      setItems(edges)
      setCursor(endCursor)
      setLastPage(!hasNextPage)
    }
  }, [itemsLoading, scrollableContent])

  // hook for loading more items

  const loadMoreHandler = () => {
    if (lastPage === false) {
      showLoading(true)

      fetchMore({
        variables: {
          first: firstValue,
          after: cursor
        },
        updateQuery: (_, { fetchMoreResult }) => {
          const {
            edges,
            pageInfo: { endCursor, hasNextPage }
          } = extractScrollableContent(fetchMoreResult, paginationContainer)
          setItems([...items, ...edges])
          setCursor(endCursor)

          if (!hasNextPage) {
            setLastPage(true)
          }
        }
      })

      showLoading(false)
    }
  }
  const goToNextPage = () => {
    if (!lastPage) {
      showLoading(true)
      fetchMore({
        variables: {
          first: firstValue,
          after: cursor
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const {
            edges,
            pageInfo: { endCursor, hasNextPage }
          } = extractScrollableContent(fetchMoreResult, paginationContainer)
          setItems(edges)
          setCursor(endCursor)
          setLastPage(!hasNextPage)
          setCurrentPage(currentPage + 1)
        }
      })
      showLoading(false)
    }
  }

  const goToPrevPage = () => {
    if (currentPage > 1) {
      showLoading(true)
      const firstItem = items[0]
      fetchMore({
        variables: {
          first: undefined,
          last: firstValue,
          before: firstItem.node.id
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const {
            edges,
            pageInfo: { endCursor }
          } = extractScrollableContent(fetchMoreResult, paginationContainer)
          setItems(edges)
          setCursor(endCursor)
          setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
        }
      })
      showLoading(false)
    }
  }
  return {
    items,
    setItems,
    error,
    loading,
    lastPage,
    loadMoreHandler,
    goToNextPage,
    goToPrevPage,
    hasNextPage: scrollableContent?.pageInfo?.hasNextPage || false,
    hasPreviousPage: scrollableContent?.pageInfo?.hasPreviousPage || false,
    currentPage
  }
}

export default useLoadMore
