import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import CreateNewFolder from '@material-ui/icons/CreateNewFolder'
// import Search from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'

import { createFolderMutation } from '../../graphql/userLibrary'
import { projectsCreateFolderMutation } from '../../graphql/userTemplates'
import UploadFile from '../UploadFile'
import ConfirmationDialog from '../ConfirmationDialog'
import UserLibrarySubActions from '../UserLibrarySubActions'
import UserLibraryTrash from '../UserLibraryTrash'
import UserProjectsDrafts from '../UserProjectsDrafts'
import { CREATE_PARTNER_PROJECT } from '../../graphql/partners'

const LibraryActions = ({
  addModalVisible,
  setAddModalVisible,
  libraryType,
  currentDirectory,
  refetch,
  showTrash,
  items,
  selectedItems,
  trashItems,
  dispatch
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const label = libraryType === 'Partnership' ? 'Project' : 'Folder'
  const [projectName, setProjectName] = useState()

  const handleClose = () => setAddModalVisible(false)
  const handleOpen = () => {
    setProjectName()
    setAddModalVisible(true)
  }
  const handleChange = (e) => setProjectName(e.target.value)
  // const handleSearch = (e) =>
  //   dispatch({ type: CHANGE_SEARCH_TERM, payload: e.target.value })

  /*
  queries & mutations
  */
  const [createProject] = useMutation(CREATE_PARTNER_PROJECT, {
    onCompleted: () => {
      refetch()
      setAddModalVisible(false)
    },
    onError: () => {
      enqueueSnackbar('Folder Creation Failed', { variant: 'error' })
      setAddModalVisible(false)
    }
  })

  const [createFolder, { data: loading }] = useMutation(
    libraryType === 'userProjects'
      ? projectsCreateFolderMutation
      : createFolderMutation,
    {
      variables: {
        parentPath: currentDirectory.path,
        name: projectName
      },
      onCompleted: () => {
        refetch()
        setAddModalVisible(false)
      },
      onError: () => {
        enqueueSnackbar('Folder Creation Failed', { variant: 'error' })
        setAddModalVisible(false)
      }
    }
  )

  const checkIfFolderExists = () => {
    const checkFolder =
      items.folders &&
      items.folders.filter((folder) => folder.name === projectName)

    if (checkFolder && checkFolder.length) return true
    return false
  }

  const onConfirmHandler = () => {
    if (projectName.includes('.trash')) {
      enqueueSnackbar('Folder name cannot include ".trash"', {
        variant: 'error'
      })
      return
    }

    if (checkIfFolderExists()) {
      enqueueSnackbar('Folder name already exists', {
        variant: 'error'
      })
      return
    }

    if (libraryType === 'Partnership')
      createProject({
        variables: {
          name: projectName,
          businessId: currentDirectory.path
        }
      })
    else createFolder()
  }

  /*
  renders
  */
  const renderMessage = (
    <>
      <Typography variant='body1' gutterBottom>
        {`Add new ${label.toLowerCase()} to the current directory`}
      </Typography>
      <TextField
        id='new-folder'
        variant='outlined'
        placeholder='Enter Folder Name'
        value={projectName}
        onChange={handleChange}
        fullWidth
        margin='dense'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {loading && <CircularProgress size={20} />}
            </InputAdornment>
          )
        }}
      />
    </>
  )

  return (
    <Box display='flex' justifyContent='space-between' pb={2}>
      {/* <TextField
        label='Search...'
        size='small'
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Search />
            </InputAdornment>
          )
        }}
      /> */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        disabled={showTrash}
      >
        {currentDirectory.path === '' && libraryType === 'userLibrary' ? (
          <Box mx={1} display='flex' alignItems='center'>
            <UserLibraryTrash />
          </Box>
        ) : null}
        {currentDirectory.path === '' && libraryType === 'userProjects' ? (
          <Box mx={1} display='flex' alignItems='center'>
            <UserProjectsDrafts />
          </Box>
        ) : null}
        {(libraryType === 'userLibrary' ||
          (currentDirectory.type === 'folders' &&
            libraryType !== 'userProjects')) && (
          <UploadFile
            types={['image', 'video', 'audio']}
            path={currentDirectory.path}
            onUpload={refetch}
            libraryType={libraryType}
            showTrash={showTrash}
          />
        )}

        <Box mx={1}>
          {(libraryType === 'userLibrary' ||
            currentDirectory.type === 'projects' ||
            libraryType === 'userProjects') && (
            <Button
              variant='outlined'
              color='primary'
              disableElevation
              disabled={showTrash}
              startIcon={<CreateNewFolder />}
              onClick={handleOpen}
            >
              {`ADD ${label}`}
            </Button>
          )}
        </Box>

        <Box display='flex' alignItems='center'>
          {currentDirectory.type !== 'businesses' && (
            <UserLibrarySubActions
              libraryType={libraryType}
              selectedItems={selectedItems}
              trashItems={trashItems}
              dispatch={dispatch}
              showTrash={showTrash}
            />
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        open={addModalVisible}
        title={`New ${label}`}
        message={renderMessage}
        onClose={handleClose}
        actionLabel='Add'
        disableAction={!projectName}
        onConfirm={() => onConfirmHandler()}
      />
    </Box>
  )
}

export default LibraryActions
