import React, { useContext, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import { UserLibraryContext } from '../../providers/UserLibraryProvider'
import { SET_TRASH } from '../../providers/UserLibraryProvider/actions-types'

const UserLibraryTrash = () => {
  /* 
    context 
    */
  const {
    state: { trash },
    dispatch
  } = useContext(UserLibraryContext)

  /* 
    helpers 
    */
  const handleToggle = () => {
    dispatch({
      type: SET_TRASH,
      payload: !trash
    })
  }

  return (
    <Fragment>
      <Button
        variant='outlined'
        color={trash ? 'secondary' : 'default'}
        disableElevation
        startIcon={trash ? <CheckBox /> : <CheckBoxOutlineBlank />}
        onClick={handleToggle}
      >
        Show Trash
      </Button>
    </Fragment>
  )
}

export default UserLibraryTrash
