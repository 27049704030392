import { styled } from '@material-ui/core/styles'

export const AnimatedHeroSection = styled('div')(
  ({ theme, paddingtop, paddingbottom }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(paddingtop - 4),
    paddingBottom: theme.spacing(paddingbottom - 4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(paddingtop),
      paddingBottom: theme.spacing(paddingbottom)
    },
    // backgroundColor: theme.palette.primary.light,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    borderRadius: theme.shape.borderRadius
  })
)
