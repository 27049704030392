import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'

import Clear from '@material-ui/icons/Clear'
import Search from '@material-ui/icons/Search'
import { SearchPaper, SearchInput } from './SearchBar.styles'

const SearchBar = ({
  label,
  minWidth,
  my,
  submitOnChange,
  initialValue,
  onChange,
  onSubmit,
  onCancelSearch
}) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)
    onChange(newValue)
  }

  const handleRequestSearch = useCallback(() => {
    if (onSubmit) {
      onSubmit(value)
    }
  }, [onSubmit, value])

  const handleCancel = useCallback(() => {
    setValue('')
    if (onCancelSearch) {
      onCancelSearch()
    }
  }, [onCancelSearch])

  const handleKeyUp = useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === 'Enter') {
        handleRequestSearch()
      } else if (e.charCode === 27 || e.key === 'Escape') {
        handleCancel()
      }
    },
    [handleRequestSearch, handleCancel]
  )

  useEffect(() => {
    if (submitOnChange) {
      const timer = setTimeout(() => {
        // update context with new search value
        onSubmit(value)
      }, 500)

      // if user still typing: clear timer
      return () => clearTimeout(timer)
    }
  }, [value])

  return (
    <Box display='flex' my={my} minWidth={minWidth}>
      <SearchPaper variant='outlined'>
        <SearchInput
          value={value}
          placeholder={label}
          inputProps={{ 'aria-label': label }}
          fullWidth
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
        {value ? (
          <IconButton type='button' onClick={handleCancel} aria-label='clear'>
            <Clear />
          </IconButton>
        ) : (
          <IconButton
            type='submit'
            onClick={handleRequestSearch}
            aria-label='search'
          >
            <Search />
          </IconButton>
        )}
      </SearchPaper>
    </Box>
  )
}

SearchBar.propTypes = {
  label: PropTypes.string,
  minWidth: PropTypes.string,
  my: PropTypes.number,
  submitOnChange: PropTypes.bool,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onCancelSearch: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
}

SearchBar.defaultProps = {
  label: 'Search',
  minWidth: 'auto',
  my: 0,
  submitOnChange: false,
  initialValue: '',
  onCancelSearch: () => {},
  onChange: () => {}
}

export default SearchBar
