import { styled, makeStyles } from '@material-ui/core'

export const GridItemWrapper = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}))

export const GridItemContent = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: '100%',
  filter: 'brightness(0.8);',
  '&:hover': {
    filter: 'brightness(1);'
  },
  transitionProperty: 'filter',
  transitionDuration: theme.transitions.duration.standard,
  transitionTimingFunction: theme.transitions.easing.easeInOut
}))

export const GridItemThumbnail = styled('img')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}))

export const GridItemVideo = styled('video')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}))

export const useGridItemStyles = makeStyles((theme) => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
}))
