import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'
import { Link as NextLink } from 'next/link'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'

import ConstructedPhrase from '../ConstructedPhrase'
import ConstructedDurationPhrase from '../ConstructedDurationPhrase'
import {
  getConstructedStmtValues,
  isVideosOnlyQuery,
  getTemplatesUseCases,
  selectedActionQuery,
  getSelectedPhrasesQuery,
  mapPhrasesToFiltersQuery
} from '../../graphql/templates'

const stmtSections = {
  types: { description: 'I want to create' },
  duration: {
    description: [
      { name: 'minDuration', label: 'with duration between' },
      { name: 'maxDuration', label: 'to' }
    ]
  },
  actions: { description: 'to' },
  platforms: { description: 'on' }
}

const TemplateConstructedStmt = ({ phraseAction, submitButtonLabel }) => {
  const [mappedFilters, setMappedFilters] = useState(false)
  const { data } = useQuery(getConstructedStmtValues, {
    ssr: false,
    fetchPolicy: 'cache-only'
  })

  const filteredTemplateTypes = data.types.filter(
    ({ typeName }) => !typeName.includes('User')
  )
  data.types = filteredTemplateTypes

  const { data: selectedActionData, refetch: refetchSelectedAction } =
    useQuery(selectedActionQuery)
  const { selectedAction } = selectedActionData || {}

  const { data: useCasesData } = useQuery(getTemplatesUseCases, {
    variables: { action: selectedAction },
    skip: !selectedAction
  })

  const { data: isVideosOnlyData, refetch: refetchIsVideosOnly } =
    useQuery(isVideosOnlyQuery)
  const { isVideosOnly } = isVideosOnlyData || false

  const { refetch: refetchSelectedPhrases } = useQuery(getSelectedPhrasesQuery)

  const { refetch: refetchSelectedFilters } = useQuery(
    mapPhrasesToFiltersQuery,
    {
      variables: mappedFilters,
      skip: !mappedFilters
    }
  )

  const { push } = useRouter()

  const updateOtherPhrases = (phraseName) => {
    switch (phraseName) {
      case 'types':
        refetchIsVideosOnly()
        break
      case 'actions':
        refetchSelectedAction()
        break
      default:
    }
  }

  const handleStmtSubmit = async () => {
    const {
      data: { selectedPhrases }
    } = await refetchSelectedPhrases()

    setMappedFilters({
      actions: selectedPhrases.actions,
      useCases: selectedPhrases.useCases
    })
    const {
      data: { mapPhrasesToFilters }
    } = await refetchSelectedFilters()

    const queryString = {}

    Object.keys({
      ...selectedPhrases,
      ...mapPhrasesToFilters
    }).forEach((item) => {
      switch (item) {
        case 'types':
          queryString.types = selectedPhrases.types
          break
        case 'platforms':
          queryString.platforms = selectedPhrases.platforms
          break
        case 'categoryNames':
          queryString.categories = mapPhrasesToFilters.categoryNames
          break
        case 'tagNames':
          queryString.searchQuery = mapPhrasesToFilters.tagNames.join(' ')
          break
        case 'minDuration':
          queryString.minDuration = selectedPhrases.minDuration
          break
        case 'maxDuration':
          queryString.maxDuration = selectedPhrases.maxDuration
          break
        default:
          break
      }
    })

    push({ pathname: '/templates/explore', query: queryString })
  }

  return (
    <>
      <form>
        <Box display='flex' flexWrap='wrap' my={2}>
          {Object.keys(data).map((phraseName) => (
            <Box display='flex' key={phraseName}>
              <ConstructedPhrase
                phraseName={phraseName}
                description={stmtSections[phraseName].description}
                data={data[phraseName] || []}
                updateOtherPhrases={updateOtherPhrases}
                phraseAction={phraseAction}
              />
              {phraseName === 'actions' &&
                useCasesData &&
                useCasesData.useCases && (
                  <ConstructedPhrase
                    key='useCases'
                    phraseName='useCases'
                    data={useCasesData.useCases}
                    phraseAction={phraseAction}
                  />
                )}
              {phraseName === 'types' && isVideosOnly && (
                <ConstructedDurationPhrase
                  key='duration'
                  phraseName='duration'
                  description={stmtSections.duration.description}
                  phraseAction={phraseAction}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box my={2} display='flex' alignItems='center' justifyContent='center'>
          <Button
            color='secondary'
            variant='contained'
            disableElevation
            onClick={handleStmtSubmit}
          >
            {submitButtonLabel}
          </Button>
        </Box>
      </form>
      <Box my={1} color='inherit' display='flex' justifyContent='center'>
        <Link
          color='inherit'
          href='/templates/explore'
          variant='overline'
          component={NextLink}
        >
          Still not sure? Check our full list of templates here!
        </Link>
      </Box>
    </>
  )
}

export default TemplateConstructedStmt
