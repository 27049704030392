import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import { getFavoritesQuery } from '../../graphql/templates'
import TemplateList from '../TemplateList'
import LoadingAnimation from '../LoadingAnimation'

const UserFavorites = () => {
  const { data, refetch: refetchFavorites } = useQuery(getFavoritesQuery, {
    fetchPolicy: 'no-cache'
  })

  if (!data.me) {
    return <LoadingAnimation />
  }

  if (!data.me.favorites.length)
    return (
      <Typography variant='h5' align='center'>
        No templates added to your favorites list yet!
      </Typography>
    )

  return (
    <TemplateList
      items={data.me.favorites.map((template) => ({ node: template }))}
      paginated
      refetchFavorites={refetchFavorites}
    />
  )
}

export default UserFavorites
