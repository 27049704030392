import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const NavTabs = ({
  tabs,
  content,
  icons,
  selected = 0,
  centered = false,
  onChange,
  color = 'primary'
}) => {
  const [currentView, setCurrentView] = useState(selected)
  return (
    <>
      <Tabs
        value={currentView}
        onChange={(event, newValue) => {
          setCurrentView(newValue)
          if (onChange) onChange(newValue)
        }}
        centered={centered}
        textColor='inherit'
        indicatorColor={color}
        aria-label='tabs'
      >
        {tabs.map((item, i) => {
          return (
            <Tab
              key={i}
              icon={icons && icons[i]}
              label={
                <Typography variant='h6' color='inherit'>
                  {item}
                </Typography>
              }
              id={`tab-${i}`}
              aria-controls={`tabpanel-${i}`}
            />
          )
        })}
      </Tabs>
      {content.map((component, i) => (
        <Box
          py={2}
          px={3}
          key={i}
          role='tabpanel'
          flexGrow={1}
          display={currentView === i ? 'flex' : 'none'}
          flexDirection='column'
          id={`tabpanel-${i}`}
          aria-labelledby={`tab-${i}`}
          color='black'
        >
          {currentView === i && component}
        </Box>
      ))}
    </>
  )
}

export default NavTabs
