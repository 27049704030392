import { styled } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

export const UserTemplateActionsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  color: 'white',
  cursor: 'default',
  zIndex: '3'
}))

export const ActionButton = styled(IconButton)(() => ({
  color: 'white'
}))
