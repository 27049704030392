/* eslint-disable no-await-in-loop */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import CloudUpload from '@material-ui/icons/CloudUpload'
import { getSignedUrlForUpload } from '../../graphql/userLibrary'
import { UPLOAD_FILES_FOR_PROJECTS } from '../../graphql/partners'
import {
  currentConsumptionQuery,
  updateUserStorageMutation
} from '../../graphql/subscriptions'

const UploadFile = ({
  types,
  buttonText,
  onUpload,
  path,
  color,
  variant,
  libraryType,
  showTrash
}) => {
  const accept = `${types.join('/*|')}/*`
  const { enqueueSnackbar } = useSnackbar()
  const [uploading, setUploading] = useState(false)

  // Get User consumption
  const userConsumption = useQuery(currentConsumptionQuery)
  // Update User consumption
  const [updateUserStorage] = useMutation(updateUserStorageMutation)
  // Get Presigned data for upload
  const [getPreSignedUrl, { loading }] = useMutation(getSignedUrlForUpload)

  const [getPresignedUrlforProject, { loading: loadingProjectFile }] =
    useMutation(UPLOAD_FILES_FOR_PROJECTS)

  const handleUpload = async ({ url, data, size }) => {
    try {
      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const response = await axios.post(url, data, options)

      onUpload()
      const {
        data: {
          currentConsumption: { id, consumedStorage }
        }
      } = userConsumption

      if (libraryType !== 'Partnership')
        updateUserStorage({
          variables: {
            id,
            newConsumedStorage: consumedStorage + size
          }
        })
      return response
    } catch (err) {
      enqueueSnackbar('File upload failed!', { variant: 'error' })
      return err
    }
  }

  const handleUploadChange = async (e) => {
    const files = Object.values(e.target.files)
    setUploading(true)
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      if (libraryType === 'Partnership') {
        const { data } = await getPresignedUrlforProject({
          variables: {
            contentType: file.type,
            folderPath: path,
            fileName: file.name
          }
        })
        const { fields, url } = data && data.uploadProjectFile

        if (fields) {
          const formData = new FormData()
          fields.forEach(({ key, value }) => {
            formData.append(key, value)
          })
          formData.append('file', file)

          await handleUpload({ data: formData, url, size: file.size })
        }
      }

      const { consumedStorage, totalStorage } =
        userConsumption.data && userConsumption.data.currentConsumption
      if (file && file.size < totalStorage - consumedStorage) {
        const { data } = await getPreSignedUrl({
          variables: {
            contentType: file.type,
            folderPath: path,
            fileName: file.name
          }
        })

        const { fields, url } = data && data.uploadFile

        if (fields) {
          const formData = new FormData()
          fields.forEach(({ key, value }) => {
            formData.append(key, value)
          })
          formData.append('file', file)

          await handleUpload({ data: formData, url, size: file.size })
        }
      } else if (file.size > totalStorage - consumedStorage) {
        // TODO: show upgrade modal
        console.log('no space')
      }
    }
    setUploading(false)
    enqueueSnackbar('File(s) uploaded successfully!', { variant: 'success' })
  }

  return (
    <>
      <input
        accept={accept}
        id='upload-file'
        onChange={handleUploadChange}
        type='file'
        name='uploaded-file'
        multiple
        style={{ display: 'none' }}
      />
      <label htmlFor='upload-file' style={{ position: 'relative' }}>
        <Button
          variant={variant}
          color={color}
          disableElevation
          startIcon={<CloudUpload />}
          disabled={showTrash || loading || uploading}
          component='span'
          fullWidth
        >
          {uploading || loading || loadingProjectFile ? (
            <CircularProgress size={21} color='inherit' />
          ) : (
            buttonText
          )}
        </Button>
      </label>
    </>
  )
}

UploadFile.propTypes = {
  types: PropTypes.array.isRequired,
  buttonText: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  path: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  libraryType: PropTypes.string
}

UploadFile.defaultProps = {
  buttonText: 'Upload File',
  path: '',
  color: 'primary',
  variant: 'outlined',
  libraryType: 'userLibrary'
}

export default UploadFile
