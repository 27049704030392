import React, { useRef } from 'react'
import Slider from 'react-slick'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import Announcement from '../Announcement'

const AnnouncementsSlider = ({ announcements }) => {
  const sliderRef = useRef(null)

  const settings = {
    lazyLoad: true,
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    customPaging: () => (
      <Box mt={-4}>
        <FiberManualRecord color='inherit' />
      </Box>
    ),
    dotsClass: 'slick-dots slick-svg-dots'
  }

  const next = () => {
    sliderRef.current.slickNext()
  }
  const previous = () => {
    sliderRef.current.slickPrev()
  }
  return (
    <Box
      my={3}
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      style={{ color: 'white' }}
    >
      <IconButton
        color='inherit'
        onClick={previous}
        style={{ transform: 'translateX(100%)', zIndex: 1 }}
      >
        <ChevronLeft style={{ fontSize: '3rem' }} />
      </IconButton>
      <Box minWidth='100%'>
        <Slider {...settings} ref={sliderRef}>
          {announcements.map(({ id, ...announcement }) => (
            <Announcement {...announcement} key={id} />
          ))}
        </Slider>
      </Box>
      <IconButton
        color='inherit'
        onClick={next}
        style={{ transform: 'translateX(-100%)' }}
      >
        <ChevronRight style={{ fontSize: '3rem' }} />
      </IconButton>
    </Box>
  )
}

export default AnnouncementsSlider
