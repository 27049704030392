import React from 'react'
import Typography from '@material-ui/core/Typography'
import { IconLogo, IconTriangle } from '../../lib/animation'
import { AnimatedHeroSection } from './TemplatesHeroSection.styles'
import AnimatedShape from '../AnimatedShape'

const TemplatesHeroSection = ({
  title,
  subtitle,
  paddingtop = 25,
  paddingbottom = 12,
  children
}) => (
  <AnimatedHeroSection paddingtop={paddingtop} paddingbottom={paddingbottom}>
    <AnimatedShape
      left='0'
      x='-70%'
      width={500}
      height={500}
      animation={IconLogo}
    />
    <Typography variant='h4' align='center'>
      {title}
    </Typography>
    <Typography variant='h6' align='center'>
      {subtitle}
    </Typography>
    {children}
    <AnimatedShape
      right='0'
      bottom='0'
      x='50%'
      y='50%'
      width={500}
      height={500}
      animation={IconTriangle}
    />
  </AnimatedHeroSection>
)

export default TemplatesHeroSection
