import { styled } from '@material-ui/core/styles'

export const AnnouncementWrapper = styled('div')(
  ({ theme, background, lightMode }) => ({
    display: 'flex',
    minHeight: 300,
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f5fbff',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: lightMode ? 'white' : 'black'
  })
)

export const AnnouncementImage = styled('div')(({ theme, image }) => ({
  display: 'flex',
  minHeight: '100%',
  padding: theme.spacing(3),
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  backgroundImage: `url(${image})`,
  backgroundPosition: '50% 50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  textAlign: 'center'
}))

export const AnnouncementDetails = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  minHeight: '100%',
  padding: '35px 50px 45px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  flex: '1',
  textAlign: 'center'
}))
