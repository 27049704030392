import React, { useState } from 'react'
import Folder from '@material-ui/icons/Folder'
import BusinessIcon from '@material-ui/icons/Business'
import SpeakerOutlined from '@material-ui/icons/SpeakerOutlined'
import VideocamOutlined from '@material-ui/icons/VideocamOutlined'
import ImageOutlined from '@material-ui/icons/ImageOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import EditOutlined from '@material-ui/icons/EditOutlined'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { StyledListItemText } from './LibraryFilesList.styles'
import { formatBytes, testFilters } from '../../utils/helpers'
import {
  OPEN_CONFIRM_DELETE,
  SELECT_ITEM,
  UNSELECT_ITEM
} from '../../providers/UserLibraryProvider/actions-types'

const ListViewItem = ({
  name,
  type,
  size,
  path,
  id,
  lastModified,
  selectedItems,
  trashItems,
  dispatch,
  handleDirectoryChange,
  currentDirectory,
  renameProject
}) => {
  const [editing, setEditing] = useState(false)
  const [fileName, setFileName] = useState(name)
  const [showToolsMenu, setShowToolsMenu] = useState(false)
  const selected = path.includes('.trash')
    ? trashItems.find((i) => i.path === path)
    : selectedItems.find((i) => i.path === path)

  const handleNameChange = (e) => setFileName(e.target.value)
  const handleEditClick = () => setEditing(!editing)
  const handleSelectChange = () =>
    dispatch({
      type: selected ? UNSELECT_ITEM : SELECT_ITEM,
      payload: { id, path, size, type }
    })
  const handleDelete = () =>
    dispatch({
      type: OPEN_CONFIRM_DELETE,
      payload: [{ id, path, size, type }]
    })
  const handleDoubleClick = () =>
    type !== 'file' && handleDirectoryChange(path, name, id, type)

  // eslint-disable-next-line radix
  const date = new Date(parseInt(lastModified)).toLocaleString()
  const renderContent = (
    <Grid container>
      <Grid item xs>
        {editing ? (
          <TextField value={fileName} onChange={handleNameChange} />
        ) : (
          <Typography variant='subtitle1'>{name}</Typography>
        )}
      </Grid>
      <Grid item xs>
        {lastModified && <Typography variant='caption'>{date}</Typography>}
      </Grid>
      {size && (
        <Grid item xs>
          <Typography variant='caption'>{formatBytes(size)}</Typography>
        </Grid>
      )}
    </Grid>
  )
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (type === 'projects') {
        renameProject({
          variables: {
            id,
            name: fileName
          }
        }).then(() => setEditing(false))
      }
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseOver={() => setShowToolsMenu(true)}
      onFocus={() => setShowToolsMenu(true)}
      onMouseLeave={() => setShowToolsMenu(false)}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <ListItem
        disableGutters
        onDoubleClick={handleDoubleClick}
        alignItems='center'
      >
        <ListItemIcon>
          <Box display='flex' alignItems='center'>
            {currentDirectory && currentDirectory.type !== 'businesses' && (
              <Checkbox
                edge='start'
                checked={selected}
                disabled={path === '.trash'}
                tabIndex={-1}
                disableRipple
                onChange={handleSelectChange}
              />
            )}
            {type === 'folders' || type === 'projects' ? (
              <Folder />
            ) : type === 'businesses' ? (
              <BusinessIcon />
            ) : testFilters('audio', name) ? (
              <SpeakerOutlined />
            ) : testFilters('videos', name) ? (
              <VideocamOutlined />
            ) : (
              <ImageOutlined />
            )}
          </Box>
        </ListItemIcon>
        <StyledListItemText primary={renderContent} />
        <ListItemSecondaryAction>
          {currentDirectory &&
            currentDirectory.type !== 'businesses' &&
            showToolsMenu && (
              <>
                <IconButton
                  edge='end'
                  aria-label='edit'
                  onClick={handleEditClick}
                >
                  <EditOutlined />
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={handleDelete}
                >
                  <DeleteOutlined />
                </IconButton>
              </>
            )}
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}

export default ListViewItem
