const type = `
  type {
    id
    typeName
    displayName
  }       
`

const interval = `
  interval {
    id
    name
    numOfMonths
  }
`

const subscriptionParent = `
  id
  name
  featured
  published
  pricePerInterval      
  ${type}
`

export const planSubscriptionFragment = `
${subscriptionParent}
... on PlanSubscription {
    id
    isDefault
    storage 
    externalAssets
    customWatermark
    planSubscriptionDetail {
      id
      lowResRenders
      watermarkedRenders
      unwatermarkedRenders
      commercialUseRenders
      discount
      ${interval}
    }
  }
`

export const rendersSubscriptionFragment = `
${subscriptionParent}
... on RendersSubscription {
    id
    rendersSubscriptionDetail {
      id
      discount
      unwatermarkedRenders    
      ${interval}
    }
  }   
`

export const storageSubscriptionFragment = `
${subscriptionParent}
... on StorageSubscription {
    id
    storage
    storageSubscriptionDetail {
      id
      discount   
      ${interval}
    }
  }  
`

const userSubscription = `
  id
  active
  createdAt
  updatedAt
  ${interval}
  ${type}  
`

export const userPlanFragment = `
  ${userSubscription}
  ...on UserPlanSubscription {
    id
    isTrial
    subscriptionParent {
      ${planSubscriptionFragment}
    }    
  } 
`

export const userRendersFragment = `
  ${userSubscription}
  ...on UserRendersSubscription {
    consumed
    subscriptionParent {
      ${rendersSubscriptionFragment}
    }
  }  
`

export const userStorageFragment = `
  ${userSubscription}
  ...on UserStorageSubscription {
    subscriptionParent {
      ${storageSubscriptionFragment}
    }
    
  }  
`

export const userConsumptionFragment = `
  id
  totalWatermarkedRenders
  consumedWatermarkedRenders
  totalUnwatermarkedRenders
  consumedUnwatermarkedRenders
  totalStorage
  consumedStorage
`
