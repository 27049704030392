import React, { createContext, useReducer } from 'react'
import initState from './init-state'
import reducer from './reducer'

export const SalesProjectsContext = createContext(null)

const SalesProjectsProvider = ({ children }) => {
  const [variablesState, dispatch] = useReducer(reducer, initState)

  return (
    <SalesProjectsContext.Provider value={{ variablesState, dispatch }}>
      {children}
    </SalesProjectsContext.Provider>
  )
}

export default SalesProjectsProvider
