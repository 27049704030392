import React, { useContext, Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { UserLibraryContext } from '../../providers/UserLibraryProvider'
import { getUserLibrary } from '../../graphql/userLibrary'
import { CHANGE_MOVE_PATH } from '../../providers/UserLibraryProvider/actions-types'
import UserLibraryMoveFilesList from '../UserLibraryMoveFilesList'
import { LibraryContainer } from '../LibraryFiles/UserLibrary.styles'

const UserLibraryMoveView = () => {
  /*
    context
  */
  const {
    dispatch,
    state: { moveDirectory }
  } = useContext(UserLibraryContext)

  /*
    queries & mutations
    */
  const {
    data: { userLibrary },
    loading
  } = useQuery(getUserLibrary, {
    fetchPolicy: 'network-only',
    variables: {
      path: moveDirectory
    }
  })

  if (moveDirectory === null) return null

  const breadcrumbs = moveDirectory.split('/')
  const renderBreadCrumbs = breadcrumbs.map((item, i) =>
    i === breadcrumbs.length - 1 ? (
      <Typography key={item}>{item}</Typography>
    ) : (
      <Button
        color='inherit'
        href='#'
        key={item}
        onClick={() => dispatch({ type: CHANGE_MOVE_PATH, payload: item })}
      >
        {item}
      </Button>
    )
  )

  const props = {
    folders:
      userLibrary &&
      userLibrary.folders.filter((folder) => folder.name !== '.trash'),
    files: userLibrary && userLibrary.files,
    loading
  }

  return (
    <Fragment>
      <Box my={1}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Button
            color='inherit'
            href='#'
            onClick={() => dispatch({ type: CHANGE_MOVE_PATH, payload: '' })}
          >
            Home
          </Button>
          {renderBreadCrumbs}
        </Breadcrumbs>
      </Box>
      <LibraryContainer>
        <UserLibraryMoveFilesList {...props} />
        {userLibrary && userLibrary.folders.length <= 0 && !loading && (
          <Box
            display='flex'
            flexDirection='column'
            minHeight={200}
            alignItems='center'
            justifyContent='center'
          >
            <Typography variant='h5' align='center'>
              No folders in the current directory!
            </Typography>
          </Box>
        )}
      </LibraryContainer>
    </Fragment>
  )
}

export default UserLibraryMoveView
