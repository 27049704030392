import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
  AnnouncementWrapper,
  AnnouncementImage,
  AnnouncementDetails
} from './Announcement.styles'

const Announcement = ({
  title,
  description,
  background,
  cover,
  callToAction,
  url,
  lightMode
}) => {
  return (
    <AnnouncementWrapper background={background} lightMode={lightMode}>
      <AnnouncementImage image={cover} />
      <AnnouncementDetails>
        <Typography variant='h3' gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom variant='subtitle1'>
          {description}
        </Typography>
        <Button
          variant='contained'
          disableElevation
          color='secondary'
          href={url}
          component='a'
          target='_blank'
        >
          {callToAction}
        </Button>
      </AnnouncementDetails>
    </AnnouncementWrapper>
  )
}

export default Announcement
