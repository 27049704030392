import React from 'react'
import Box from '@material-ui/core/Box'
import LoadingAnimation from '../LoadingAnimation'
import { useInfiniteScroll } from '../../hooks'
import ResponsiveMasonry from '../MasonryLayout/ResponsiveMasonry'
import Masonry from '../MasonryLayout/Masonry'
import SalesProject from '../SalesProject'

const SalesProjectsList = ({ salesProjectsReturn }) => {
  const { items, error, loading } = useInfiniteScroll(
    salesProjectsReturn,
    30,
    'salesProjects'
  )

  return (
    <Box p={2}>
      {error && <p>{error.message}</p>}
      {items && (
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 5 }}
        >
          <Masonry columnsCount={2} gutter='0px'>
            {items.map(({ node }) => (
              <SalesProject key={node.id} data={node} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}

      {loading && <LoadingAnimation />}
    </Box>
  )
}

export default SalesProjectsList
