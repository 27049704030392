import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import Warning from '@material-ui/icons/Warning'
import { green, red } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { resendVerificationTokenMutation } from '../../graphql/account'

const VerifiedStatus = ({ statuses }) => {
  const { enqueueSnackbar } = useSnackbar()

  const isVerified = statuses.find(({ name }) => name === 'IS_VERIFIED')
  const [resendToken, { loading }] = useMutation(
    resendVerificationTokenMutation,
    {
      onCompleted: () => {
        enqueueSnackbar('Token sent!', {
          variant: 'success'
        })
      },
      onError: () => {
        // TODO: show error message(s) instead
        enqueueSnackbar('Token sending failed!', {
          variant: 'error'
        })
      }
    }
  )

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='flex-end'
      color={isVerified ? green[500] : red[700]}
    >
      {isVerified ? (
        <>
          <Typography variant='button'>Verified</Typography>
          <VerifiedUser />
        </>
      ) : (
        <>
          <Typography variant='button'>Email Not Verified</Typography>
          <Warning />
          <Box ml={1}>
            <Button onClick={resendToken} disabled={loading} variant='outlined'>
              Resend Verification Link
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default VerifiedStatus
