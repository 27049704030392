import gql from 'graphql-tag'

const salesProjectFragment = `
    id
    account {
      id
      logoUrl
      watermarkUrl
      user {
        id
        firstName
        lastName
      }
    }
    userTemplate {
        id
        name
        renderUrl
        thumbnailUrl
        currentStatus { id status }
        type { typeName displayName slug }
        ... on UserImageTemplate {
            id
        }
        ... on UserVideoTemplate {
            id
        }
    }
    headline
    caption
    status
    notes {
      message
      owner
      createdAt
    }
    customerName
    customerEmail
    price
    paypalBuyUrl
`
export const GET_USER_SALES_ACCOUNT = gql`
  query userSalesAccount {
    userSalesAccount {
      id
      logoUrl
      paypalEmail
      watermarkUrl
    }
  }
`
export const UPDATE_USER_SALES_ACCOUNT = gql`
  mutation updateSalesAccount(
    $accountId: ID!
    $logoUrl: String
    $paypalEmail: String
    $watermarkUrl: String
  ) {
    updateSalesAccount(
      input: {
        accountId: $accountId
        logoUrl: $logoUrl
        paypalEmail: $paypalEmail
        watermarkUrl: $watermarkUrl
      }
    ) {
      id
      logoUrl
      paypalEmail
      watermarkUrl
    }
  }
`
export const GET_SALES_PROJECT = gql`
  query salesProject($id: ID!) {
    salesProject(id: $id) {
      ${salesProjectFragment}
    }
  }
`
export const GET_SALES_PROJECTS = gql`
  query salesProjects(
    $first: Int
    $after: String
    $search: String
    $accountId: ID!
    $status: SalesProjectStatus
    $orderBy: SalesProjectOrderByInput
    ) {
    salesProjects(input: {
        first: $first
        after: $after
        search: $search
        accountId: $accountId
        status: $status
        orderBy: $orderBy
      }) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ${salesProjectFragment}
            }
        }
    }
  }
`
export const CREATE_SALES_PROJECT = gql`
  mutation createSalesProject(
    $accountId: ID!
    $userTemplateId: ID!
    $headline: String!
    $caption: String
    $customerName: String!
    $customerEmail: String!
    $paypalBuyUrl: String!
    $price: Float!
  ) {
    createSalesProject(
      input: {
        accountId: $accountId
        userTemplateId: $userTemplateId
        headline: $headline
        caption: $caption
        customerName: $customerName
        customerEmail: $customerEmail
        paypalBuyUrl: $paypalBuyUrl
        price: $price
      }
    ) {
      ${salesProjectFragment}
    }
  }
`
export const UPDATE_SALES_PROJECT = gql`
  mutation updateSalesProject(
    $projectId: ID!
    $headline: String!
    $caption: String!
    $paypalBuyUrl:String!
    $price:Float!
    $customerName: String!
    $customerEmail: String!
  ) {
    updateSalesProject(
      input: {
        projectId: $projectId
        headline: $headline
        caption: $caption
        paypalBuyUrl: $paypalBuyUrl
        price: $price
        customerName: $customerName
        customerEmail: $customerEmail
      }
    ) {
      ${salesProjectFragment}
    }
  }
`
export const ADD_SALES_PROJECT_NOTE = gql`
  mutation addSalesProjectNote(
    $projectId: ID!
    $message: String!
    $owner: String!
  ) {
    addSalesProjectNote(
      input: { projectId: $projectId, message: $message, owner: $owner }
    ) {
      id
      message
      owner
      createdAt
    }
  }
`
export const UPDATE_SALES_PROJECT_STATUS = gql`
  mutation updateSalesProjectStatus(
    $projectId: ID!
    $status: SalesProjectStatus!
  ) {
    updateSalesProjectStatus(
      input: { projectId: $projectId, status: $status }
    ) {
      ${salesProjectFragment}
    }
  }
`
export const SEND_REVIEW_EMAIL_TO_CLIENT = gql`
  mutation sendReviewEmailToClient($id: ID!) {
    sendReviewEmailToClient(id: $id)
  }
`
