import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import { getAnnouncements } from '../../graphql/data'
import AnnouncementsSlider from '../AnnouncementsSlider'
import LoadingAnimation from '../LoadingAnimation'
import VerifiedStatus from '../VerifiedStatus'

const UserHome = ({ user }) => {
  const {
    data: { announcements },
    loading
  } = useQuery(getAnnouncements)

  return (
    <>
      <VerifiedStatus statuses={user.statuses} />
      {loading ? (
        <Box display='flex' p={10} justifyContent='center'>
          <LoadingAnimation />
        </Box>
      ) : (
        <AnnouncementsSlider announcements={announcements} />
      )}
    </>
  )
}

export default UserHome
