export const partnerList = [
  'Trainings',
  'Newsletters',
  'Blogs Posts',
  'Bonuses',
  'Intake Application',
  'Intake Process Video',
  'Reseller Tools',
  'Agency Toolkit',
  'Agency Guide'
]
export const listLinks = {
  Newsletters: {
    link: '/newsletters',
    isExternal: false
  },
  'Blogs Posts': {
    link: '/blogs',
    isExternal: false
  },
  Trainings: {
    link: '/local-partners-training',
    isExternal: false
  },
  Bonuses: {
    link: 'https://imlocalpartner.com/reseller-ty',
    isExternal: true
  },
  'Intake Application': {
    link: ' https://docs.google.com/forms/d/1I-mXGesrsUyNWn5G1o96R2tI00yvFC6kSqncSa5G0oM/viewform?edit_requested=true',
    isExternal: true
  },
  'Intake Process Video': {
    link: 'https://player.vimeo.com/video/594584983?h=7c36e9557e',
    isExternal: true
  },
  'Reseller Tools': {
    link: 'https://all-in-one.dropmock.com/reseller-tools',
    isExternal: true
  },
  'Agency Toolkit': {
    link: 'https://all-in-one.dropmock.com/lp-toolkit-access',
    isExternal: true
  },
  'Agency Guide': {
    link: ' https://dropmock.s3.us-west-2.amazonaws.com/Toolkit/Local_partners/DropMock+Local+Agency+Partners+Guide+.pdf',
    isExternal: true
  }
}
