import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import ListViewItem from './ListViewItem.component'
import { AlternateList } from './LibraryFilesList.styles'

const UserLibraryMoveFilesList = ({ folders, loading, libraryType }) => {
  const renderFolders =
    folders &&
    folders.map((folder) => (
      <ListViewItem
        key={folder.path}
        {...folder}
        type='folder'
        libraryType={libraryType}
      />
    ))

  return (
    <>
      {!loading ? (
        <AlternateList dense disablePadding>
          {renderFolders}
        </AlternateList>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          minHeight={200}
        >
          <Skeleton animation='wave' />
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton />
          <Skeleton animation='wave' />
          <Skeleton />
        </Box>
      )}
    </>
  )
}

export default UserLibraryMoveFilesList
