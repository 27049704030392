import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import Template from '../Template'
import { StyledTypography } from './TemplateList.style'
import ResponsiveMasonry from '../MasonryLayout/ResponsiveMasonry'
import Masonry from '../MasonryLayout/Masonry'

const TemplateList = ({ items, title, paginated, refetchFavorites }) => {
  return useMemo(
    () => (
      <Box p={2}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5, 1200: 6 }}
        >
          <StyledTypography variant='h5' align='left'>
            {title}
          </StyledTypography>
          <Masonry columnsCount={2} gutter='0px'>
            {items &&
              items.map((template) => (
                <Template
                  key={paginated ? template.node.id : template.id}
                  data={paginated ? template.node : template}
                  refetchFavorites={refetchFavorites}
                />
              ))}
          </Masonry>
        </ResponsiveMasonry>
        {/* <Box p={2} style={{ display: 'flex', justifyContent: 'center' }}>
        {loading && <LoadingAnimation />}
      </Box> */}
      </Box>
    ),
    [title, items, paginated]
  )
}

TemplateList.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  paginated: PropTypes.bool
}

TemplateList.defaultProps = {
  items: [],
  title: '',
  paginated: false
}

export default TemplateList
