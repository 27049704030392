import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'

import { styled } from '@material-ui/core/styles'

export const SearchPaper = styled(Paper)(() => ({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1
}))
