import React, { useState, useContext, useEffect } from 'react'
import Warning from '@material-ui/icons/Warning'
import { yellow } from '@material-ui/core/colors'
import { useMutation } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useSnackbar } from 'notistack'
import { UserLibraryContext } from '../../providers/UserLibraryProvider'
import LibraryActions from '../LibraryActions'
import LibraryFiles from '../LibraryFiles'
import UserLibraryStats from '../UserLibraryStats'
import { testFilters } from '../../utils/helpers'
import {
  deleteFilesMutation,
  deleteFoldersMutation
} from '../../graphql/userLibrary'
import * as actions from '../../providers/UserLibraryProvider/actions-types'

const UserLibrary = ({ user }) => {
  const [openAddFolder, setOpenAddFolder] = useState(false)

  const { dispatch, state, library } = useContext(UserLibraryContext)
  const { refetch, userLibrary, loading } = library
  const { enqueueSnackbar } = useSnackbar()
  const {
    search,
    filters,
    currentDirectory,
    trash,
    showTrash,
    selectedItems,
    trashItems
  } = state

  useEffect(() => {
    if (currentDirectory.path.includes('.trash')) {
      dispatch({
        type: actions.SET_SHOW_TRASH,
        payload: true
      })
    } else {
      dispatch({
        type: actions.SET_SHOW_TRASH,
        payload: false
      })
    }
  }, [currentDirectory.path])

  const checkFilters = (name) =>
    filters.find((filter) => testFilters(filter, name))

  const items = {
    type: 'folders',
    folders:
      userLibrary &&
      userLibrary.folders.filter(
        (folder) =>
          folder.name.includes(search) &&
          (trash ? true : folder.name !== '.trash')
      ),
    files:
      userLibrary &&
      userLibrary.files.filter(
        (file) => file.name.includes(search) && Boolean(checkFilters(file.name))
      )
  }

  const [deleteFiles, { loading: deleteFilesLoading }] = useMutation(
    deleteFilesMutation,
    {
      onCompleted: async () => {
        await refetch()
        /**
         * TODO: UPDATE USER'S STORAGE AFTER FILES DELETE
         * Better use subscriptions to update apollo client when database updates
         * * Subscriptions have problems with GraphQLShield
         * */
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )

  const [deleteFolders, { loading: deleteFoldersLoading }] = useMutation(
    deleteFoldersMutation,
    {
      onCompleted: async () => {
        await refetch()
        /**
         * TODO: UPDATE USER'S STORAGE AFTER FOLDER DELETE
         * Better use subscriptions to update apollo client when database updates
         * * Subscriptions have problems with GraphQLShield
         * */
        enqueueSnackbar('Delete Successful!', { variant: 'success' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
        dispatch({ type: actions.CLEAR_SELECTED_ITEMS })
      },
      onError: () => {
        enqueueSnackbar('Delete Failed!', { variant: 'error' })
        dispatch({ type: actions.CLOSE_CONFIRM_DELETE })
      }
    }
  )
  const handleDelete = () => {
    const files = []
    const folders = []
    selectedItems.forEach((item) =>
      item.type !== 'folder' ? files.push(item) : folders.push(item)
    )
    if (files.length)
      deleteFiles({
        variables: {
          filePaths: files.map((file) => file.path)
        }
      })
    if (folders.length)
      deleteFolders({
        variables: {
          folderPaths: folders.map((folder) => folder.path)
        }
      })
  }
  return (
    <>
      {user.statuses.filter(({ name }) => name === 'IS_VERIFIED').length > 0 ? (
        <>
          <Box display='flex' justifyContent='space-between'>
            <LibraryActions
              addModalVisible={openAddFolder}
              addButtonName='ADD FOLDER'
              setAddModalVisible={setOpenAddFolder}
              currentDirectory={currentDirectory}
              refetch={refetch}
              libraryType='userLibrary'
              showTrash={showTrash}
              items={items}
              selectedItems={selectedItems}
              trashItems={trashItems}
              dispatch={dispatch}
            />
            <UserLibraryStats />
          </Box>
          <LibraryFiles
            items={items}
            libraryType='userLibrary'
            loading={loading}
            refetch={refetch}
            state={state}
            dispatch={dispatch}
            deleteFilesLoading={deleteFilesLoading}
            deleteFoldersLoading={deleteFoldersLoading}
            handleDelete={handleDelete}
          />
        </>
      ) : (
        <Box display='flex' p={5} justifyContent='center' alignItems='center'>
          <Warning style={{ fontSize: 50, color: yellow[700] }} />
          <Typography variant='h5'>Please verify your email first</Typography>
        </Box>
      )}
    </>
  )
}

export default UserLibrary
