import React from 'react'
import Box from '@material-ui/core/Box'
import { capitalize } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import HomeOutlined from '@material-ui/icons/HomeOutlined'
import CloudOutlined from '@material-ui/icons/CloudOutlined'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import WorkOutline from '@material-ui/icons/WorkOutline'
import BusinessCenterOutlined from '@material-ui/icons/BusinessCenterOutlined'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import { useRouter } from 'next/router'

import PartnerProjectProvider from '../../providers/PartnerProjectProvider'
import UserLibraryProvider from '../../providers/UserLibraryProvider'
import UserProjectProvider from '../../providers/UserProjectsProvider'
import NavTabs from '../NavTabs'
import UserProjects from '../UserProjects'
import UserLibrary from '../UserLibrary'
import PartnerLibrary from '../PartnerLibrary'
import UserFavorites from '../UserFavorites'
import UserHome from '../UserHome'
import ColorHeroSection from '../ColorHeroSection'
import TemplatesHeroSection from '../TemplatesHeroSection'
import TemplateConstructedStmt from '../TemplateConstructedStmt'
import SalesProjects from '../SalesProjects'

const DashboardContent = ({ user, section }) => {
  const { agency, business, salesAccount } = user

  let partner = { type: '' }
  if (business && agency?.active) partner = { type: 'business', ...business }
  if (agency && agency.active) partner = { type: 'agency', ...agency }

  const router = useRouter()

  const labels = [
    'home',
    'projects',
    'uploads',
    'favorites',
    ...(partner && partner.type ? ['partners'] : []),
    ...(salesAccount ? ['sales'] : [])
  ]
  const tabs = [
    'Home',
    'My Projects',
    'Media',
    'Favorites',
    ...(partner && partner.type ? ['Agency Partner'] : []),
    ...(salesAccount ? ['Sales System'] : [])
  ]
  const icons = [
    <HomeOutlined />,
    <WorkOutline />,
    <CloudOutlined />,
    <FavoriteBorder />,
    ...(partner && partner.type ? [<BusinessCenterOutlined />] : []),
    ...(salesAccount ? [<MonetizationOn />] : [])
  ]
  const content = [
    <UserHome user={user} />,
    <UserProjectProvider>
      <UserProjects user={user} />
    </UserProjectProvider>,
    <UserLibraryProvider>
      <UserLibrary user={user} />
    </UserLibraryProvider>,
    <UserFavorites user={user} />,
    ...(partner && partner.type
      ? [
          <PartnerProjectProvider>
            <PartnerLibrary user={user} partner={partner} />
          </PartnerProjectProvider>
        ]
      : []),
    ...(salesAccount ? [<SalesProjects account={salesAccount} />] : [])
  ]

  const selected = section ? labels.findIndex((label) => label === section) : 0

  const onTabChange = (selection) =>
    router.push(
      `/dashboard?section=${labels[selection]}`,
      `/dashboard/${labels[selection]}`,
      { shallow: true }
    )

  return (
    <>
      <ColorHeroSection
        title=''
        subtitle=''
        paddingtop={8}
        paddingbottom={0}
        withContainer={false}
      >
        <TemplatesHeroSection
          title={`Welcome ${capitalize(user.firstName)}`}
          subtitle='What would you like to create today?'
          paddingtop={7}
          paddingbottom={12}
        >
          <TemplateConstructedStmt submitButtonLabel='Show me' />
        </TemplatesHeroSection>
      </ColorHeroSection>
      <Box mt={-8.75} zIndex={1} color='white'>
        <Container maxWidth='lg'>
          <NavTabs
            tabs={tabs}
            icons={icons}
            centered
            color='secondary'
            selected={selected}
            content={content}
            onChange={onTabChange}
          />
        </Container>
      </Box>
    </>
  )
}

export default DashboardContent
