import React from 'react'
import SalesProjectsGroup from '../SalesProjectsGroup'
import SalesProjectsProvider from '../../providers/SalesProjectsProvider'

const SalesProjects = ({ account }) => {
  return (
    <SalesProjectsProvider>
      <SalesProjectsGroup account={account} />
    </SalesProjectsProvider>
  )
}

export default SalesProjects
