import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import { currentConsumptionQuery } from '../../graphql/subscriptions'
import { formatBytes } from '../../utils/helpers'

const UserLibraryStats = () => {
  const {
    data: { currentConsumption }
  } = useQuery(currentConsumptionQuery)

  const { totalStorage, consumedStorage } = currentConsumption || {
    totalStorage: 0,
    consumedStorage: 0,
    id: null
  }

  return (
    <Box mb={4} display='flex' justifyContent='center'>
      <Paper>
        <Box padding={2}>
          <Typography variant='overline' align='center' component='p'>
            Remaining Storage
          </Typography>
          <Typography variant='h6' align='center' gutterBottom>
            {formatBytes(totalStorage - consumedStorage)}
          </Typography>
          <Typography align='right' variant='caption' component='p'>
            {Math.floor((consumedStorage / totalStorage) * 100)}%
          </Typography>
          {currentConsumption && (
            <LinearProgress
              variant='determinate'
              value={(consumedStorage / totalStorage) * 100}
            />
          )}
          <Box mt={1}>
            <Typography
              variant='overline'
              component={Link}
              color='inherit'
              href='https://bonus.dropmock.com/dm-ai-storage'
              target='_blank'
            >
              Want More? Upgrade your Storage for FREE!
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default UserLibraryStats
