import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import axios from 'axios'

import IconButton from '@material-ui/core/IconButton'
import { uploadAvatarMutation } from '../../graphql/account'
import { AccountAvatarImage } from './AccountAvatar.styles'
import LoadingAnimation from '../LoadingAnimation'

const AccountAvatar = ({ avatar, initials, onChange, isCustomAvatar }) => {
  const [getPreSignedUrl, { loading }] = useMutation(uploadAvatarMutation)

  const handleAvatarChange = (e) => {
    const file = e.target.files[0] && e.target.files[0]
    getPreSignedUrl({
      variables: {
        contentType: file.type,
        fileName: file.name,
        isSalesLogo: isCustomAvatar
      }
    }).then((data) => {
      const { signedUrl } = data.data.uploadAvatar
      // SEND A PUT REQUEST TO S3 WITH THE NEW SIGNEDREQUEST TO UPLOAD THE FILE
      const options = {
        headers: {
          'Content-Type': file.type
        }
      }
      axios
        .put(signedUrl, file, options)
        .then(() => onChange(signedUrl.split('?')[0]))
        .catch(() => {})
    })
  }

  return (
    <>
      <input
        onChange={(e) => handleAvatarChange(e)}
        accept='image/*'
        id='icon-button-file'
        type='file'
        style={{ display: 'none' }}
      />
      <label htmlFor='icon-button-file'>
        <IconButton
          color='primary'
          aria-label='upload picture'
          component='span'
        >
          {loading ? (
            <LoadingAnimation />
          ) : (
            <AccountAvatarImage src={avatar}>
              {avatar || initials}
            </AccountAvatarImage>
          )}
        </IconButton>
      </label>
    </>
  )
}

AccountAvatar.propTypes = {
  user: PropTypes.shape.isRequired
}

export default AccountAvatar
