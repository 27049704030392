export const RENDERING_STATUSES = ['RENDERING', 'QUEUED']

export const userProjectToggle = {
  drafts: {
    label: 'drafts',
    values: ['EDITING', 'FAILED', ...RENDERING_STATUSES]
  },
  published: { label: 'published', values: ['SUCCESSFUL'] }
}

export const orderByOptions = {
  name_ASC: 'name_ASC',
  name_DESC: 'name_DESC',
  createdAt_ASC: 'createdAt_ASC',
  createdAt_DESC: 'createdAt_DESC'
}

export const types = [
  { displayName: 'UserImageTemplate', slug: 'user-image' },
  { displayName: 'UserVideoTemplate', slug: 'user-video' }
]

export const orderByValues = {
  Date: ['createdAt_ASC', 'createdAt_DESC'],
  Title: ['name_ASC', 'name_DESC']
}

export const pageSize = 30
