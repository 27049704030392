import { useEffect, useState } from 'react'

const useSingleAndDoubleClick = (
  params,
  actionSingleClick,
  actionDoubleClick,
  delay = 250
) => {
  const [click, setClick] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1 && actionSingleClick) actionSingleClick(params)
      setClick(0)
    }, delay)

    if (click === 2 && actionDoubleClick) actionDoubleClick(params)

    return () => clearTimeout(timer)
  }, [click])

  return () => setClick((prev) => prev + 1)
}

export default useSingleAndDoubleClick
