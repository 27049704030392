import React, { createContext, useReducer } from 'react'
import { useQuery } from '@apollo/react-hooks'
import initState from './init-state'
import reducer from './reducer'
import { getUserProjects } from '../../graphql/userTemplates'

export const UserProjectsContext = createContext({
  state: initState,
  dispatch: () => {},
  projects: {}
})

const UserProjectsProvider = ({ children }) => {
  const [variablesState, dispatch] = useReducer(reducer, initState)
  const {
    data: { userProjects },
    loading,
    refetch
  } = useQuery(getUserProjects, {
    fetchPolicy: 'network-only',
    variables: {
      path: variablesState.currentDirectory.path,
      orderBy: variablesState.sortQuery,
      isTrash: false
    }
  })

  return (
    <UserProjectsContext.Provider
      value={{
        variablesState,
        dispatch,
        projects: { refetch, userProjects, loading }
      }}
    >
      {children}
    </UserProjectsContext.Provider>
  )
}

export default UserProjectsProvider
