import React, { useState } from 'react'
import { useRouter } from 'next/router'
import EditOutlined from '@material-ui/icons/EditOutlined'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import WatchIcon from '@material-ui/icons/VisibilityOutlined'
import ModifyIcon from '@material-ui/icons/BorderColorOutlined'
import TrashIcon from '@material-ui/icons/DeleteOutlined'
import RateReviewIcon from '@material-ui/icons/RateReviewOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import ConfirmationDialog from '../ConfirmationDialog'
import SimpleMenu from '../SimpleMenu'
import SalesDialog from '../SalesDialog'

import {
  UserTemplateActionsContainer,
  ActionButton
} from '../UserTemplateActions/UserTemplateActions.styles'
import {
  UPDATE_SALES_PROJECT_STATUS,
  SEND_REVIEW_EMAIL_TO_CLIENT
} from '../../graphql/salesSystem'

const SalesProjectActions = ({ id, type, templateId, status, projectInfo }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState(false)
  const [isSalesDialogOpen, toggleSalesDialog] = useState(false)
  const [isSendEmailDialogOpen, toggleSendEmailDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { push } = useRouter()

  const [updateStatus] = useMutation(UPDATE_SALES_PROJECT_STATUS)
  const [sendEmail, { loading: sending }] = useMutation(
    SEND_REVIEW_EMAIL_TO_CLIENT,
    {
      variables: { id },
      onCompleted: () => {
        toggleSendEmailDialog(false)
        enqueueSnackbar('Email Sent to Client', { variant: 'success' })
      },
      onError: (e) => {
        toggleSendEmailDialog(false)
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    }
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSaleCompleted = async () => {
    await updateStatus({
      variables: {
        projectId: id,
        status: 'SOLD'
      }
    })
  }

  const menuItems = [
    {
      label: 'Watch',
      icon: WatchIcon,
      action: () => push(`/templates/watch/${templateId}`)
    },
    {
      label: 'Review Sales Page',
      icon: RateReviewIcon,
      action: () => window.open(`/templates/sales/${id}`, '_blank').focus()
    },
    {
      label: 'Edit Project Info',
      icon: EditOutlined,
      action: () => toggleSalesDialog(true)
    },
    {
      label: 'Send to client',
      icon: EmailIcon,
      action: () => toggleSendEmailDialog(true)
    },
    ...(status === 'APPROVED'
      ? [
          {
            label: 'Sale Completed',
            icon: ReceiptIcon,
            action: handleSaleCompleted
          }
        ]
      : []),
    {
      label: 'Modify Project',
      icon: ModifyIcon,
      action: () => push(`/editor/${type.slug.replace('-', '/')}/${templateId}`)
    },
    {
      label: 'Move To Trash',
      icon: TrashIcon,
      action: () => toggleConfirmationDialog(true)
    }
  ]

  return (
    <UserTemplateActionsContainer>
      <ActionButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreIcon />
      </ActionButton>
      <SimpleMenu
        handleClose={handleClose}
        menuItems={menuItems}
        anchorEl={anchorEl}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => toggleConfirmationDialog(!isConfirmationDialogOpen)}
        message='Are you sure you would like to delete this project and all its progress?'
        // actionLabel={loading ? <LoadingAnimation /> : 'Delete'}
        // disableAction={loading}
        // onConfirm={deleteUserTemplate}
      />
      <ConfirmationDialog
        open={isSendEmailDialogOpen}
        onClose={() => toggleSendEmailDialog(false)}
        message='Send project review email to client?'
        actionLabel='Send'
        disableAction={sending}
        onConfirm={sendEmail}
      />
      <SalesDialog
        open={isSalesDialogOpen}
        onClose={() => toggleSalesDialog(false)}
        userTemplateId={id}
        initialEditValues={{
          ...projectInfo
        }}
        isEdit
      />
    </UserTemplateActionsContainer>
  )
}
export default SalesProjectActions
