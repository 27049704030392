/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { ButtonProgress } from './ConfirmationDialog.styles'

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  actionLabel,
  disableAction,
  onConfirm,
  loading,
  component
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>{title || 'Confirmation'}</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        {message || 'Confirmation Message'}
      </DialogContentText>
      {component ? component() : null}
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' color='secondary' onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant='outlined'
        onClick={onConfirm}
        disabled={disableAction || loading}
        color='primary'
        autoFocus
      >
        {actionLabel || 'Confirm'}
        {loading && <ButtonProgress size={24} color='primary' />}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog
