import React from 'react'
import * as Yup from 'yup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from '@apollo/react-hooks'
import {
  CLONE_USER_IMAGE_TEMPLATE,
  CLONE_USER_VIDEO_TEMPLATE
} from '../../graphql/userTemplates'
import CredentialsForm from '../CredentialsForm'

const CloneDialog = ({ open, onClose, userTemplateId, type, refetch }) => {
  const inputFields = [{ label: 'Name', name: 'name', type: 'text' }]
  const [cloneTemplate, { loading }] = useMutation(
    type?.typeName === 'UserImageTemplate'
      ? CLONE_USER_IMAGE_TEMPLATE
      : CLONE_USER_VIDEO_TEMPLATE,
    {
      onCompleted: () => {
        refetch()
        onClose()
      },
      awaitRefetchQueries: true
    }
  )
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  })
  const initialValues = {
    name: ''
  }

  const handleSubmit = async (values) => {
    await cloneTemplate({
      variables: {
        id: userTemplateId,
        ...values
      }
    })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Clone Template</DialogTitle>
      <DialogContent>
        <CredentialsForm
          inputFields={inputFields}
          validationSchema={validationSchema}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          submitText='Confirm'
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CloneDialog
