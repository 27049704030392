import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { durationRangeQuery } from '../../graphql/templates'

const ConstructedDurationPhrase = ({ description, phraseAction }) => {
  const { data, client } = useQuery(durationRangeQuery)

  const durationChangeHandler = (e) => {
    client.writeData({
      data: { [e.target.name]: e.target.value }
    })
  }

  const durationBlurHandler = (e) => {
    if (phraseAction) phraseAction(e.target.name, e.target.value)
  }

  return (
    <>
      {description.map(({ name, label }) => (
        <Box key={name} display='flex'>
          <Typography variant='h5' component='span'>
            {label}
          </Typography>
          <Box width='35px' mx={1}>
            <TextField
              type={name}
              name={name}
              id={name}
              value={data[name]}
              onChange={durationChangeHandler}
              onBlur={durationBlurHandler}
            />
          </Box>
        </Box>
      ))}
    </>
  )
}

export default ConstructedDurationPhrase
