import React from 'react'
import ViewList from '@material-ui/icons/ViewList'
import ViewComfy from '@material-ui/icons/ViewComfy'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import SortingButton from '../SortingButton'
import {
  CHANGE_DIRECTORY_VIEW,
  CHANGE_FILTER
} from '../../providers/UserLibraryProvider/actions-types'

const LibrarySortFilter = ({
  directoryView,
  filters,
  dispatch,
  currentDirectory
}) => {
  const handleChangeView = (view) =>
    dispatch({ type: CHANGE_DIRECTORY_VIEW, payload: view })

  const handleFilterChange = (e) =>
    dispatch({ type: CHANGE_FILTER, payload: e.target.value })

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      py={1}
      alignItems='center'
    >
      <Box display='flex'>
        <Box display='flex' alignItems='center'>
          {currentDirectory.type === 'folders' && (
            <Select
              id='show-type'
              variant='outlined'
              autoWidth
              margin='dense'
              value={filters.join(',')}
              onChange={handleFilterChange}
            >
              <MenuItem value='audio,images,videos'>
                <em>All</em>
              </MenuItem>
              <MenuItem value='audio'>Audio</MenuItem>
              <MenuItem value='images'>Images</MenuItem>
              <MenuItem value='videos'>Videos</MenuItem>
            </Select>
          )}
        </Box>
        <Box display='flex' alignItems='center' px={1}>
          <SortingButton
            criteria='Date'
            //   direction={direction}
            //   handleClick={() => handleClick(orderByValues[value])}
          />
          <SortingButton
            criteria='Title'
            //   direction={direction}
            //   handleClick={() => handleClick(orderByValues[value])}
          />
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <IconButton
          onClick={() => handleChangeView('list')}
          color={directoryView === 'list' ? 'primary' : 'default'}
        >
          <ViewList />
        </IconButton>
        <Divider orientation='vertical' flexItem variant='middle' />
        <IconButton
          onClick={() => handleChangeView('grid')}
          color={directoryView === 'grid' ? 'primary' : 'default'}
        >
          <ViewComfy />
        </IconButton>
      </Box>
    </Box>
  )
}

export default LibrarySortFilter
