import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { addArticle } from '../../utils/helpers'
import { toggleSelectedphraseMutation } from '../../graphql/templates'

const ConstructedPhrase = ({
  phraseName,
  description,
  data,
  updateOtherPhrases,
  phraseAction
}) => {
  const [togglePhrase] = useMutation(toggleSelectedphraseMutation, {
    onError: (e) => console.log('error', e)
  })

  const phraseValueChangeHandler = (event) => {
    const { id, slug } = data[event.target.value] || { id: 0 }

    togglePhrase({
      variables: { id, dataArr: data }
    })

    if (updateOtherPhrases) updateOtherPhrases(phraseName, slug)

    if (phraseAction) phraseAction(phraseName, slug)
  }

  return (
    <Box display='flex' color='inherit'>
      <Typography variant='subtitle1' component='span'>
        {description}
      </Typography>
      <Box mx={1} color='inherit'>
        <Select
          defaultValue='any'
          name={phraseName}
          id={phraseName}
          onChange={phraseValueChangeHandler}
        >
          <MenuItem value='any'>
            <em>Any</em>
          </MenuItem>
          {data.map(({ slug, name, displayName, isSelected }, index) => (
            <MenuItem key={slug} value={index} selected={isSelected}>
              {phraseName === 'types'
                ? addArticle(name || displayName)
                : name || displayName}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default ConstructedPhrase
