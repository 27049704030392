import React from 'react'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Button from '@material-ui/core/Button'

const SortingButton = ({ criteria, direction, handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      endIcon={
        direction && (direction === 'UP' ? <ArrowDropDown /> : <ArrowDropUp />)
      }
    >
      {criteria}
    </Button>
  )
}
export default SortingButton
