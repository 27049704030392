import React, { useContext, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import { UserProjectsContext } from '../../providers/UserProjectsProvider'
import { SET_DRAFTS } from '../../providers/UserProjectsProvider/action-types'

const UserProjectsDrafts = () => {
  const {
    variablesState: { drafts },
    dispatch
  } = useContext(UserProjectsContext)

  const handleToggle = () => {
    dispatch({
      type: SET_DRAFTS,
      payload: !drafts
    })
  }

  return (
    <Fragment>
      <Button
        variant='outlined'
        color={drafts ? 'secondary' : 'default'}
        disableElevation
        startIcon={drafts ? <CheckBox /> : <CheckBoxOutlineBlank />}
        onClick={handleToggle}
      >
        Show Drafts
      </Button>
    </Fragment>
  )
}

export default UserProjectsDrafts
