/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react'
import * as Yup from 'yup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  CREATE_SALES_PROJECT,
  GET_USER_SALES_ACCOUNT,
  UPDATE_SALES_PROJECT
} from '../../graphql/salesSystem'
import CredentialsForm from '../CredentialsForm'

const SalesDialog = ({
  open,
  onClose,
  userTemplateId,
  isEdit,
  initialEditValues
}) => {
  const inputFields = [
    { label: 'Headline', name: 'headline', type: 'text' },
    { label: 'Caption', name: 'caption', type: 'text' },
    { label: 'Client Name', name: 'customerName', type: 'text' },
    { label: 'Client Email', name: 'customerEmail', type: 'email' },
    { label: 'Price', name: 'price', type: 'number' },
    { label: 'Paypal Buy Link', name: 'paypalBuyUrl', type: 'text' }
  ]
  const [createSalesProject, { loading: creating }] =
    useMutation(CREATE_SALES_PROJECT)
  const [updateSalesProject, { loading: updating }] =
    useMutation(UPDATE_SALES_PROJECT)
  const { loading, data } = useQuery(GET_USER_SALES_ACCOUNT)
  const { id: accountId } = (data && data.userSalesAccount) || {}

  const validationSchema = Yup.object().shape({
    headline: Yup.string(),
    caption: Yup.string(),
    price: Yup.number().positive().min(0.1).required('Price is required'),
    paypalBuyUrl: Yup.string().required('Paypal Buy Link is required'),
    customerName: Yup.string().required('Client Name is required'),
    customerEmail: Yup.string()
      .email('Please enter a valid email')
      .required('Client email is required')
  })

  const initialValues = {
    headline: '',
    caption: '',
    customerName: '',
    customerEmail: '',
    price: 0,
    paypalBuyUrl: ''
  }

  const handleSubmit = async (values) => {
    await createSalesProject({
      variables: {
        accountId,
        userTemplateId,
        ...values
      }
    })
    onClose()
  }

  const handleUpdate = async (values) => {
    await updateSalesProject({
      variables: {
        projectId: userTemplateId,
        ...values
      }
    })
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='sales-dialog-title'
      aria-describedby='sales-dialog-description'
    >
      <DialogTitle id='sales-dialog-title'>
        {isEdit ? 'Edit Sales Project' : 'Create Sales Project'}
      </DialogTitle>
      <DialogContent>
        <CredentialsForm
          inputFields={inputFields}
          validationSchema={validationSchema}
          initialValues={isEdit ? initialEditValues : initialValues}
          handleSubmit={isEdit ? handleUpdate : handleSubmit}
          submitText='Confirm'
          loading={loading || creating || updating}
        >
          {!isEdit && (
            <DialogContentText id='sales-dialog-description'>
              Create project from template and send it to your client via email
              for approval
            </DialogContentText>
          )}
        </CredentialsForm>
      </DialogContent>
    </Dialog>
  )
}

export default SalesDialog
