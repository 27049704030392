import React from 'react'
import Head from 'next/head'
import { getConstructedStmtValues } from '../graphql/templates'
import getQuery from '../lib/getQuery'
import DashboardContent from '../components/DashboardContent'

const Index = ({
  user,
  setLightTheme,
  setShowFooter,
  setShowHeader,
  setEditorHeader
}) => {
  setLightTheme(false)
  setShowFooter(false)
  setShowHeader(true)
  setEditorHeader(false)

  return (
    <>
      <Head>
        <title>DropMock | Dashboard</title>
      </Head>
      <DashboardContent user={user} />
    </>
  )
}

Index.getInitialProps = async ({ apolloClient }) => {
  await getQuery(apolloClient, {
    query: getConstructedStmtValues
  })
}

export default Index
