import { styled } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'

export const StyledListItemText = styled(ListItemText)(() => ({
  userSelect: 'none'
}))
export const AlternateList = styled(List)(({ theme }) => ({
  '& > div': {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  '& > div:nth-child(odd)': {
    backgroundColor: 'rgba(0,0,0,0.03)'
  }
}))
