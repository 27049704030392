import PropTypes from 'prop-types'
import React from 'react'

const Masonry = ({ children, columnsCount, gutter, isPrompt }) => {
  const getColumns = () => {
    const columns = Array.from({ length: columnsCount }, () => [])

    React.Children.forEach(children, (child, index) => {
      columns[index % columnsCount].push(child)
    })

    return columns
  }

  const Column = ({ column }) => {
    return column.map((item, i) => (
      <div key={i} style={{ marginTop: i > 0 ? gutter : undefined }}>
        {item}
      </div>
    ))
  }

  const Columns = () => {
    return getColumns().map((column, i) => (
      <div
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'stretch',
          flex: isPrompt ? '0 0 320px' : 1,
          width: 0,
          marginLeft: i > 0 ? gutter : undefined
        }}
      >
        <Column column={column} />
      </div>
    ))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        width: '100%'
      }}
    >
      <Columns />
    </div>
  )
}

Masonry.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  columnsCount: PropTypes.number,
  gutter: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  isPrompt: PropTypes.bool
}

Masonry.defaultProps = {
  columnsCount: 3,
  gutter: '0',
  className: null,
  style: {},
  isPrompt: false
}

export default Masonry
