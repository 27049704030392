import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import initState from './init-state'
import reducer from './reducer'

export const PartnerProjectContext = createContext({
  state: initState,
  dispatch: () => {},
  library: {}
})

const PartnerProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)



  return (
    <PartnerProjectContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </PartnerProjectContext.Provider>
  )
}

PartnerProjectProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default PartnerProjectProvider
